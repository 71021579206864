
.ArchitekturaKrajobrazu{
    padding-bottom: .9rem;

    &__container{
        width: 1280px;
        margin: 0 auto;
        background-color: #156606;
        box-shadow: 0px 0px 20px #000000B3;
    }
    &__img {        
        @include rwd(large-phone) {
            padding: 0 2rem;
            margin-bottom: 2rem;
        }
    }
    &__bg{
        
    }
    &__textBox{
        padding: 2rem 4rem;
        @include rwd(large-phone) {
            padding: 0 2rem;
        }
        .titleSection{
            font-size: 40px;
            color: $cBody;
            font-weight: 900;
            margin-bottom: 1rem;
            @include rwd(large-phone) {
                font-size: 30px;
                line-height: 28px;
                padding: 0;
                margin: 0;
                margin-bottom: 2rem;
            }
        }
        .text{
            color: $cBody;
            font-size: 13px;
            line-height: 22px;
        }
    }
    .btn{
        margin: 1rem 0 0 0;
        top: 0;
        transform: none;
        a{
            color: #156606;
            &:hover{
                color: $cWhite!important;
                text-decoration: none;
            }
        }
        &:hover{
            border-color: $cWhite!important;
            background: $cGreen!important;
        }
    }   
    h3 {
        margin: 1.3rem 0;
        font-size: 26px;
        color: $cGreen;
    } 

    &__quote{
        color: $cGreen;
        font-size: 36px;
        font-weight: 700;
        line-height: 44px;
        margin: 5rem 0 1rem;
        @include rwd(large-phone) {
            padding: 0 2rem;    
            font-size: 26px;
            line-height: 28px;
            padding: 0 2rem;
        }
    }

    &--Grey{
        background: #EFEFEF;
        padding: 4rem 0;

        &__img{
            text-align: right;
        }
    }
}
    