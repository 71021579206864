.mainSlider{    
    padding-top: 0;
    padding-bottom: 0;
    @include rwd(large-phone) {
        height: 50vh;
        min-height: 300px;
    }
    .item {
        @include rwd(large-phone) {
            height: 50vh;
            min-height: 300px;
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
            position: relative;
        }
        .textBox {
            position: absolute;
            width: 100%;
            top: 30%;
            @include rwd(large-phone) {
                top: 15%;
            }
            &__container{
                background-color: rgba(255,255,255,.7);
                padding: 2.5rem;
                margin: 0 auto;
                width: 50%;
                @include rwd(large-phone) {
                    padding: .5rem;
                    width: 70%;
                }
                .mainText{
                    color: $cBody;
                    font-size: 46px;
                    font-weight: 700;
                    @include rwd(large-phone) {
                        font-size: 26px;
                        font-weight: 700;
                    }
                }
                .secondText{
                    color: $cSubtitle;
                    font-size: 30px;
                    font-weight: 400;
                    @include rwd(large-phone) {
                        font-size: 20px;
                    }
                }
                .btnBox{
                    margin-top: 3rem;
                }
                .button--green{                    
                    display: block;            
                    width: 240px;
                    height: 50px;
                    border: 1px solid #146505;
                    border-radius: 30px;
                    @include vertical-center();
                    @include rwd(large-phone) {          
                        width: 200px;
                        height: 40px;
                    }
                    a{
                        padding: 5px 0 0 0;
                        display: block;
                        font-size: 20px;
                        text-transform: uppercase;
                        @include rwd(large-phone) {
                            padding: 2px 0 0 0;
                            font-size: 16px;
                        }
                    }
                }
            }
            
        }

    }
    margin-bottom: 30px;
    .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: -2rem;
        z-index: 5;
        position: relative;
    }
}