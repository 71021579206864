.JakToDziala{
    padding-bottom: .9rem;

    &__container{
        width: 1280px;
        margin: 0 auto;
        background-color: #156606;
        box-shadow: 0px 0px 20px #000000B3;
    }
    &__bg{
        
    }
    &__img {        
        @include rwd(large-phone) {
            padding: 0 2rem;
        }
    }
    &__textBox{
        padding: 2rem 4rem;
        @include rwd(large-phone) {
            padding: 0 2rem;
        }
        .titleSection{
            font-size: 40px;
            color: $cBody;
            font-weight: 900;
            margin-bottom: 1rem;
            @include rwd(large-phone) {
                font-size: 30px;
                line-height: 28px;
            }
        }
        .subtitleSection{
            color: $cYellow;
            font-size: 26px;
            font-weight: 700;
            line-height: 27px;
            padding-bottom: .5rem;
            margin-bottom: 0;
            @include rwd(large-phone) {
                font-size: 22px;
                line-height: 18px;
            }
        }
        .text{
            color: $cBody;
            font-size: 13px;
            line-height: 22px;
        }
    }
    .btn{
        margin: 1rem 0 0 0;
        top: 0;
        transform: none;
        a{
            color: #156606;
            &:hover{
                color: $cWhite!important;
                text-decoration: none;
            }
        }
        &:hover{
            border-color: $cWhite!important;
            background: $cGreen!important;
        }
    }   
    h3 {
        margin: 1.3rem 0;
        font-size: 26px;
        color: $cGreen;
    } 

    h4 {
        margin: 1rem 0 .8rem;
        font-size: 18px;
        color: $cBody;
    } 

    &__quote{
        color: $cGreen;
        font-size: 36px;
        font-weight: 700;
        line-height: 44px;
        margin: 5rem 0 1rem;
    }

    &--BGpause{
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}
.JakZrozumiec{
    background-color: #EFEFEF;
    .titleSection{
        font-size: 48px;
        color: $cBody;
        font-weight: 900;
        margin-bottom: 1rem;
        @include rwd(large-phone) {
            font-size: 30px;
            line-height: 28px;
        }
    }
    &__textBox{
        h4{
            margin: 2rem 0 1rem;
            font-size: 18px;
            font-weight: 700;
            color: $cBody;
        }
        p.txt{

        }
    }
}
.PoprawJakosc{
    background-color: #fff;
    .titleSection{
        font-size: 48px;
        color: $cBody;
        font-weight: 900;
        margin-bottom: 3rem;
        @include rwd(large-phone) {
            font-size: 30px;
            line-height: 28px;
        }
    }
    &__textBox{
        h4{
            margin: 0 0 1rem 0;
            font-size: 24px;
            font-weight: 900;
            color: $cBody;
            text-align: left;
            text-transform: uppercase;
        }
        p.txt{
            margin-top: 2rem;
            font-size: 14px;
            &--left{
                padding-right:11rem;
                text-align: left;
                @include rwd(large-phone) {
                    padding: 0;
                    margin-bottom: 1rem;
                    text-align: justify;
                }
            }
            &--right{
                padding-left:11rem;
                text-align: right;
                @include rwd(large-phone) {
                    padding: 0;
                    margin-bottom: 1rem;
                    text-align: justify;
                }
            }
        }
    }
    &__wpinka{
        position: relative;
        top: -26rem;
        text-align: center;
        margin-bottom: -26rem;
        @include rwd(tablet) {
            display: none;
        }
    }
}