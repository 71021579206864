.NajlepszeDla{
    margin-top: 4rem;
    @include rwd(large-phone) {
        margin: 0;
    }
    background-color: #EFEFEF;
    &__icons{
        display: flex;
        align-items: end;
        -webkit-justify-content: space-around;
        justify-content: space-around;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;

        .icoBox{
            text-align: center;
            @include rwd(large-phone) {
                margin: 0 1rem 2rem;
            }
            img{
                margin-bottom: .5rem;
            }
            p{
                font-size: 12px;
                color: #231F20;
                @include rwd(large-phone) {
                    font-size: 10px;
                }
            }
        }
    }

    .titleSection {
        text-align: center;
        margin-bottom: 2rem;
    }
}