.stworzTrawnik{
    padding-bottom: .9rem;

    &__container{
        max-width: 1280px;
        margin: 0 auto;
        background-color: #156606;
        box-shadow: 0px 0px 20px #000000B3;
    }
    &__bg{
        
    }
    &__textBox{
        padding: 2rem 4rem;
        .titleSection{
            font-size: 30px;
            color: #fff;
            font-weight: 700;
            margin-bottom: 1rem;
            @include rwd(large-phone) {
                font-size: 30px;
            }
        }
        .text{
            color: #fff;
            font-size: 13px;
            line-height: 22px;
        }
    }
    .btn{
        margin: 1rem 0 0 0;
        top: 0;
        transform: none;
        a{
            color: #156606;
            &:hover{
                color: $cWhite!important;
                text-decoration: none;
            }
        }
        &:hover{
            border-color: $cWhite!important;
            background: $cGreen!important;
        }
    }
}

.MainPowtarzalne{
    padding-bottom: 0;
    &__textBox{
        padding: 2rem 4rem;
        .titleSection{
            margin-bottom: 1rem;
        }
        .text{
            line-height: 27px;
        }
    }
    .btn{
        margin: 1rem 0 0 0;
        top: 0;
        transform: none;
        a{
            color: #156606;
            &:hover{
                color: $cWhite!important;
                text-decoration: none;
            }
        }
    }
    @include rwd(large-phone) {
        padding: 0 15px;
    }
}
.BGpause{

    &--odd {
        background: url('../../dist/images/svg/pasek_poziomy_1.svg');
        background-repeat: no-repeat;
        text-align: center;
        margin: 0 auto;
        width: 663px;
        height: 134px;
        @include rwd(large-phone) {
            display: none!important;
        }
    }

    &--even {
        background: url('../../dist/images/svg/pasek_poziomy_2.svg');
        background-repeat: no-repeat;
        text-align: center;
        margin: 0 auto;
        width: 663px;
        height: 134px;
        @include rwd(large-phone) {
            display: none!important;
        }
    }
    
}
