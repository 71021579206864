.btn{
    border: 1px solid $cGreen;
    border-radius: 30px;
    background-color: $cWhite;
    margin: 0 auto;
    @include vertical-center();
    color: $cGreen;
    a{
        color: $cGreen;
        font-size: 12px;
        padding: 1rem 1.7rem;
        font-weight: 700;
    }
    &:hover{
        color: $cWhite;
        border-color: $cWhite;
        background: $cGreen;
        a{
            color: $cWhite;
            text-decoration: none;
        }
    }
    &--reverse{
        border: 1px solid $cWhite;
        border-radius: 30px;
        background-color: $cGreen;
        color: $cWhite;
        margin: 0 auto;
        @include vertical-center();
        &:hover{
            color: $cGreen!important;
            border-color: $cGreen!important;
            background: $cGreen!important;
        }
        a{
            color: #fff;
        }
    }
}
.hvr-radial-out:active, .hvr-radial-out:focus, .hvr-radial-out:hover {
	color: $cGreen;
	border-color:$cGreen;
	background-color: #fff;
}