.OchronaSrodowiska{    
    background: $cGrey;
    padding-bottom: 0;
    margin-top: 4rem;
    @include rwd(large-phone) {
        margin: 0;
    }
	.parallax {        
        /* Set a specific height */
        min-height: 60vh;   
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .titleSection {
            @include vertical-center();
            color: $cWhite;
        }
    }
    &__txt{
        padding-bottom: 4rem;
        text-align: center;
        p{
            line-height: 28px;
        }
    }
}