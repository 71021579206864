

.header{
    $root: &;
    &__menu{
        ul {
            li{
                @include rwd(tablet) {
                    margin: .2rem 0;
                    padding-left: .4rem;
                }
            }
        }
    }
    
    &__icoPhone{
        margin: 0;
        a.ico{
            color: $cGreen;
            font-size: 20px;
            font-weight: 500;
            font-size: 12px;
            font-weight: 500;
            }
        img.ico{
            margin-right: .8rem;
            width: 25px;
            @include rwd(tablet) {
                width: 25px;
            }
        }
        &.nofixed{
            display: block;
        }
        &.fixed{
            display: none;
        }
    }
        
    &__logo{
        width: 160px;
        height: auto;
        @include rwd(tablet) {
            width: 160px;
            height: auto;
        }
        @include rwd(small-tablet) {
            width: 150px;
            height: auto;
        }
        @include rwd(large-phone) {
            width: 120px;
            height: auto;
        }
        @include rwd(phone) {
            width: 100px;
            height: auto;
        }
    }

    #navbarNavDropdown {   
        justify-content: center;
        flex-direction: row-reverse;
    }
    
    //// HEADER FIXED 

    &--fixed{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: #fff;
        width: 100%;
        max-width: unset;
        #navbarNavDropdown {   
            flex-direction: unset; 
        }
        .header__logo{
            height: 35px;
            width:auto;
        }
        #{$root}__icoPhone{
            &.nofixed{
                display: none;
                @include rwd(tablet) {
                    display: none!important;
                }
            }
            &.fixed{
                display: block;
            }
        }
        
        #{$root}__icoPhone{
            margin: 0;
            a.ico{
                color: $cGreen;
                font-size: 12px;
                font-weight: 500;
            }
            img.ico{
                margin-right: .8rem;
                height: 25px;
            }
            
        }

        ul#menu-main-manu a {
            color: #339a99;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 400;
        }

        .header__calltoUs .svg img {
            width: 20px;
            height: 20px;
        }
        .header__calltoUs a {
            color: #339a99;
            font-size: 22px;
            margin-left: .4rem;
        }
        .navbar-brand{
        }

        .navbar{
            padding-top:0;
            padding-bottom:0;
            width: 100%;
            margin: 0 auto;
            justify-content: center;
            text-align: center;
            max-width: 1122px;
            @include rwd(tablet) {
                margin: 0 auto;
                padding: 10px 10% 0;
            }
        }

        .dropdown-menu{
            margin-top:0 !important;   
        }

    }
    //// END HEADER FIXED 
}