.JakPobrac{
    margin-top: 4rem;
    padding-bottom: 0;
    @include rwd(large-phone) {
        margin-top: 2rem;
    }
    &__titleBox{
        display: flex;
        margin: 0 auto;
        align-items: center;
        margin-bottom: 3rem;
        flex-grow: 2;
        justify-content: center;
        @include rwd(large-phone) {
            flex-direction: column;
        }

        .ico{
            margin-right: .5rem;
            img {
                @include rwd(large-phone) {
                    width: 150px;
                }
            }
        }
        .txt{
            margin-left: .5rem;
            text-align: center;

            .titleSection {
                text-align: center;
                margin-bottom: 2rem;
            }
        }
    }
    &__icons{
        display: flex;
        align-items: baseline;
        -webkit-justify-content: space-around;
        justify-content: space-around;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;

        .icoBox{
            text-align: center;
            max-width: 130px;
            img{
                margin-bottom: .5rem;
            }
            p{
                font-size: 12px;
                color: #231F20;
            }
        }
    }

    &__button{
        margin-top: 4rem;
        text-align: center;
    }
}