body{
    color: $cBody;
}
.mainContainer{
    max-width: 1122px;
    margin:0 auto;

    @media (min-width:992px) and (max-width:1199px){
        padding-left:25px;
        padding-right:25px;
    }

    @media (min-width:1200px) and (max-width:1500px){
        padding-left:50px;
        padding-right:50px;
    }
}
.container-fluid {
    
    @include rwd(large-tablet,true) {
        padding-right: unset;
        padding-left: unset;
    }
}
a{
    color: $cBody;
/* 
    &:hover{
        color:$textColor;
        text-decoration: underline;
        @include rwd(medium-tablet,true) {
            -webkit-animation-name: hvr-pulse;
            animation-name: hvr-pulse;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
    }
     */
}

/* Pulse */
@-webkit-keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  @keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
/* BACKGROUND */



/* COLORS */


/*  others */


p{
    font-size:14px;
    @media(min-width:768px){
        font-size:15px;
    }
    @media(min-width:1200px){
        font-size:16px;
    }
}


.slide{
    width:100%;
    height:100%;
    position:relative;
}

.psuedo-background-img{
    @include rwd(large-phone) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.titleSection{
    padding-bottom: 2rem;
    color: $cBlack;
    line-height: 36px;
    @include FontBold(900);

    @include media(xsmall) {
        font-size:22px;
    }

    @include media(small) {
        font-size:26px;
    }

    @include media(medium) {
        font-size:32px;
    }

    @include media(large) {
        font-size:36px;
    }

    @include media(xxlarge) {
        font-size:40px;
    }

    strong,
    &--bold{
        @include FontBold(500);
    }

    &--small{
        font-size:26px;

        @include media(xsmall) {
            font-size:28px;
        }
    
        @include media(small) {
            font-size:30px;
        }
    
        @include media(medium) {
            font-size:32px;
        }
    
        @include media(large) {
            font-size:34px;
        }
    
        @include media(xxlarge) {
            font-size:36px;
        }
    }

    &--xsmall{
        font-size:20px;

        @include media(xsmall) {
            font-size:22px;
        }

        @include media(medium) {
            font-size:24px;
        }
 
    }

}

.subtitleSection{
    padding-bottom: 1rem;
    color: $cSubtitle;

    @include media(xsmall) {
        font-size:16px;
    }

    @include media(small) {
        font-size:18px;
    }

    @include media(medium) {
        font-size:24px;
    }

    @include media(large) {
        font-size:26px;
    }

    @include media(xxlarge) {
        font-size:26px;
    }

    strong,
    &--bold{
        @include FontBold(500);
    }

    &--small{
        font-size:26px;

        @include media(xsmall) {
            font-size:28px;
        }
    
        @include media(small) {
            font-size:30px;
        }
    
        @include media(medium) {
            font-size:32px;
        }
    
        @include media(large) {
            font-size:34px;
        }
    
        @include media(xxlarge) {
            font-size:36px;
        }
    }

    &--xsmall{
        font-size:16px;

        @include media(xsmall) {
            font-size:18px;
        }

        @include media(medium) {
            font-size:18px;
        }
 
    }

}

.novisible{
    display: none!important;
}
.showvisible{
    display: block!important;
    padding: 0 !important;
}
.centerOfScreen{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); /* Yep! */
        width: 48%;
        height: 59%;
}
ul {
    list-style: url('../../dist/images/ptaszek_ok.png');
    font-size: 14px;
    margin: 0 2rem;
    li {
        margin: .5rem 0;
        padding-left: .4rem;
    }
}
.icoTxt {
    color: $cGreen;
    font-size: 12px;
    @include media(xsmall) {
        font-size:14px;
    }

    @include media(small) {
        font-size:14px;
    }

    @include media(medium) {
        font-size:14px;
    }

    @include media(large) {
        font-size:12px;
    }

    @include media(xxlarge) {
        font-size:12px;
    }
}