
    .woocommerce div.product form.cart div.quantity {
        @include rwd(large-phone) {
            flex-direction: row;
            display: flex;
            text-align: center;
            justify-content: center;
            float: unset!important;
            margin: unset!important;
        }
    }
#mainContainerShop{
    @include rwd(medium-tablet) {
        padding: 0 15px
    }
    
    .titleSection{
        @include rwd(large-phone) {
            font-size: 30px;
            line-height: 28px;
        }
    }


    .woocommerce-breadcrumb {
        display: none!important;
    }
    .btn.cta__button--green {
        margin: 0rem auto;
        @include rwd(large-phone) {
            margin: 2rem auto 0;
        }
    }
    .cart{
        margin-top:2rem;
    }
    .quantity input {
        border: 1px solid $cGreen;
        padding: .4rem;
        width: 4rem;
        border-radius: 30px;
        width: 100px;
        height: 55px;
        margin-top: -3px;
        @include rwd(large-phone) {
            width: 80px;
            height: 35px;
        }
    }
    .posted_in {
        display: none!important;
    }
    .tabs.wc-tabs {
        display: none!important;
    }
    #tab-description h2{
        display: none!important;
    }
    div.product p.price, div.product span.price {
        color: $cGreen;
        font-size: 2rem;
        font-weight: 900;
        margin: 1rem 0;
    }
}

#sidebar{
    display: none!important;
}
.button.wc-forward{
    display: none!important;
}
.woocommerce-message {
	border-top-color: $cGreen!important;
}
.woocommerce-message::before {
	content: "\e015";
	color: $cGreen!important;
}
.woocommerce-Price-currencySymbol {
	margin-left: .2rem;
}
.cart_totals {
    h2{
        padding-bottom: 2rem;
        color: $cBlack;
        line-height: 2.3rem;
        font-weight: 700;
        @include rwd(large-phone) {
            font-size: 26px;
            text-align: center;
        }
    }
}
.wc-proceed-to-checkout button,
button#place_order{
    margin-top: 2rem;
}
.pageStandard  #respond input#submit, .pageStandard  a.button, .pageStandard  button.button, .pageStandard  input.button {
	color: #fff!important;
	background-color: $cGreen!important;
	border: 1px solid $cGreen!important;
}
.pageStandard #respond input#submit:hover, .pageStandard a.button:hover, .pageStandard button.button:hover, .pageStandard input.button:hover {
	background-color: white!important;
	color: $cGreen!important;
	border: 1px solid $cGreen!important;
}
.pageStandard .breadcrumbs,
.pageStandard .woocommerce-form-coupon-toggle{
    display: none!important;
}


.woocommerce-checkout {
    form {
        .form-row input.input-text, 
        .form-row textarea {
            box-sizing: border-box;
            width: 100%;
            margin: 3px 0;
            padding: 5px;
            outline: 0;
            line-height: normal;
            border: 1px solid $cGreen;
            
        }
        .form-row {
            padding: 3px;
            margin: 0 0 6px;
            display: contents;
        }
        .form-row label {
            line-height: unset;
            margin-top: 10px;
        }
        h3{
            margin-top: 2rem;
            @include rwd(large-phone) {
                margin-top: 1rem;
                font-size: 1.35rem;
            }
        }
    }
    h3{
        padding-bottom: 2rem;
        color: $cBlack;
        line-height: 1.5rem;
        font-weight: 500;
        @include rwd(large-phone) {
            padding-bottom: 1rem;
        }
    }
    #ship-to-different-address {        
        @include rwd(large-phone) {
            margin-top: 2rem;
        }
    }

    #billing_state_field{
        display: none!important;
    }
}
.woocommerce-order {
    h3{
        font-size: 1.35rem;
    }
    .woocommerce-order-details__title{
        font-size: 1.5rem;
        font-weight: 500;
        @include rwd(large-phone) {
            font-size: 1.1rem;  
        }
    }
    .woocommerce-column__title{
        font-size: 1.5rem;
        font-weight: 500;
        @include rwd(large-phone) {
            font-size: 1.1rem;  
        }
    }
    .woocommerce-thankyou-order-received,
    .woocommerce-thankyou-order-details,
    .woocommerce-bacs-bank-details {
        border: 0px solid $cGreen;
        padding: 2rem;
    }
    ul.order_details li {
        border-right: 1px dashed $cGreen!important;
    }
    section.woocommerce-order-details{
        margin:0;
        padding: 1rem 2rem;
    }
    section.woocommerce-customer-details{
        margin-bottom: 2em;
        padding: 1rem 2rem;
    }
}

.woocommerce .col2-set .col-1, 
.woocommerce-page .col2-set .col-1,
.woocommerce .col2-set .col-2,
.woocommerce-page .col2-set .col-2 {
    max-width: 48%; 
    @include rwd(tablet) {
        max-width: 100%; 
        margin: 1.5rem 0;        
    }
}

.woocommerce table.shop_table th,
.woocommerce table.shop_table td {
    font-size: 14px!important;
    @include rwd(large-phone) {
        font-size: 12px!important;
    }
}