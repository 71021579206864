@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* przykład 
##SCSS 
div {
  @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
}
 ##CSS 
div {
  -webkit-transform: scale3d(2.5, 2, 1.5);
  -moz-transform: scale3d(2.5, 2, 1.5);
  -ms-transform: scale3d(2.5, 2, 1.5);
  -o-transform: scale3d(2.5, 2, 1.5);
  transform: scale3d(2.5, 2, 1.5);
}
*/
/* Vertical Centering SCSS */
/*
## SCSS 
div {
  @include vertical-center();
}
## CSS 
div {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
*/
/* Retina-Ready Image SCSS */
/* 
###SCSS
.image {
  background: url("my-image.png") no-repeat;
  @include retina-image("my-image2x.png", 1000px, 500px);
}
###CSS
.image {
  background: url("my-image.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), 
(-o-min-device-pixel-ratio: 2.6 / 2), 
(-webkit-min-device-pixel-ratio: 1.3), 
(min-device-pixel-ratio: 1.3), 
(min-resolution: 1.3dppx) {
  .image {
    background-image: url("my-image2x.png");
    background-size: 1000px 500px;
  }
}
 */
/* Arrow with Four Optional Directions SCSS */
/*
 ###SCSS

// without arguments (default)
div { 
  @include arrow(); 
}

// with custom arguments
div {
  @include arrow(up, 10px, #efefef);
}
###CSS
// without arguments (default)
div {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555;
}

// with custom arguments
div {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #efefef;
}
*/
/* Example of use is at the bottom of file */
/*
  Example:

    ul {
      margin-bottom: 15px;

      @include rwd(tablet, true) {
        margin-bottom: 30px;
      }
      
      li {
        margin-bottom: 20px;

        @include rwd(tablet) {
          margin-bottom: 10px;
        }
      }
    }

  Result:

    ul {
      margin-bottom: 15px;
    }

    ul li {
      margin-bottom: 20px;
    }

    @media (min-width: 769px) {
      ul {
        margin-bottom: 30px;
      }
    }

    @media (max-width: 768px) {
      ul li {
        margin-bottom: 10px;
      }
    }

*/
body {
  color: #111110;
}

.mainContainer {
  max-width: 1122px;
  margin: 0 auto;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mainContainer {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .mainContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1025px) {
  .container-fluid {
    padding-right: unset;
    padding-left: unset;
  }
}

a {
  color: #111110;
  /* 
    &:hover{
        color:$textColor;
        text-decoration: underline;
        @include rwd(medium-tablet,true) {
            -webkit-animation-name: hvr-pulse;
            animation-name: hvr-pulse;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
    }
     */
}

/* Pulse */
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

/* BACKGROUND */
/* COLORS */
/*  others */
p {
  font-size: 14px;
}

@media (min-width: 768px) {
  p {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 16px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;
}

@media (max-width: 480px) {
  .psuedo-background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.titleSection {
  padding-bottom: 2rem;
  color: #000000;
  line-height: 36px;
  font-weight: 900;
}

@media (min-width: 460px) {
  .titleSection {
    font-size: 22px;
  }
}

@media (min-width: 576px) {
  .titleSection {
    font-size: 26px;
  }
}

@media (min-width: 768px) {
  .titleSection {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .titleSection {
    font-size: 36px;
  }
}

@media (min-width: 1410px) {
  .titleSection {
    font-size: 40px;
  }
}

.titleSection strong, .titleSection--bold {
  font-weight: 500;
}

.titleSection--small {
  font-size: 26px;
}

@media (min-width: 460px) {
  .titleSection--small {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .titleSection--small {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .titleSection--small {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .titleSection--small {
    font-size: 34px;
  }
}

@media (min-width: 1410px) {
  .titleSection--small {
    font-size: 36px;
  }
}

.titleSection--xsmall {
  font-size: 20px;
}

@media (min-width: 460px) {
  .titleSection--xsmall {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .titleSection--xsmall {
    font-size: 24px;
  }
}

.subtitleSection {
  padding-bottom: 1rem;
  color: #727272;
}

@media (min-width: 460px) {
  .subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 576px) {
  .subtitleSection {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 1410px) {
  .subtitleSection {
    font-size: 26px;
  }
}

.subtitleSection strong, .subtitleSection--bold {
  font-weight: 500;
}

.subtitleSection--small {
  font-size: 26px;
}

@media (min-width: 460px) {
  .subtitleSection--small {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .subtitleSection--small {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .subtitleSection--small {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .subtitleSection--small {
    font-size: 34px;
  }
}

@media (min-width: 1410px) {
  .subtitleSection--small {
    font-size: 36px;
  }
}

.subtitleSection--xsmall {
  font-size: 16px;
}

@media (min-width: 460px) {
  .subtitleSection--xsmall {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .subtitleSection--xsmall {
    font-size: 18px;
  }
}

.novisible {
  display: none !important;
}

.showvisible {
  display: block !important;
  padding: 0 !important;
}

.centerOfScreen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* Yep! */
  width: 48%;
  height: 59%;
}

ul {
  list-style: url("../../dist/images/ptaszek_ok.png");
  font-size: 14px;
  margin: 0 2rem;
}

ul li {
  margin: .5rem 0;
  padding-left: .4rem;
}

.icoTxt {
  color: #156606;
  font-size: 12px;
}

@media (min-width: 460px) {
  .icoTxt {
    font-size: 14px;
  }
}

@media (min-width: 576px) {
  .icoTxt {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .icoTxt {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .icoTxt {
    font-size: 12px;
  }
}

@media (min-width: 1410px) {
  .icoTxt {
    font-size: 12px;
  }
}

body {
  font-family: 'Montserrat', sans-serif;
}

.btn {
  border: 1px solid #156606;
  border-radius: 30px;
  background-color: #FFFFFF;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #156606;
}

.btn a {
  color: #156606;
  font-size: 12px;
  padding: 1rem 1.7rem;
  font-weight: 700;
}

.btn:hover {
  color: #FFFFFF;
  border-color: #FFFFFF;
  background: #156606;
}

.btn:hover a {
  color: #FFFFFF;
  text-decoration: none;
}

.btn--reverse {
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  background-color: #156606;
  color: #FFFFFF;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn--reverse:hover {
  color: #156606 !important;
  border-color: #156606 !important;
  background: #156606 !important;
}

.btn--reverse a {
  color: #fff;
}

.hvr-radial-out:active, .hvr-radial-out:focus, .hvr-radial-out:hover {
  color: #156606;
  border-color: #156606;
  background-color: #fff;
}

.ArchitekturaKrajobrazu {
  padding-bottom: .9rem;
}

.ArchitekturaKrajobrazu__container {
  width: 1280px;
  margin: 0 auto;
  background-color: #156606;
  box-shadow: 0px 0px 20px #000000B3;
}

@media (max-width: 480px) {
  .ArchitekturaKrajobrazu__img {
    padding: 0 2rem;
    margin-bottom: 2rem;
  }
}

.ArchitekturaKrajobrazu__textBox {
  padding: 2rem 4rem;
}

@media (max-width: 480px) {
  .ArchitekturaKrajobrazu__textBox {
    padding: 0 2rem;
  }
}

.ArchitekturaKrajobrazu__textBox .titleSection {
  font-size: 40px;
  color: #111110;
  font-weight: 900;
  margin-bottom: 1rem;
}

@media (max-width: 480px) {
  .ArchitekturaKrajobrazu__textBox .titleSection {
    font-size: 30px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    margin-bottom: 2rem;
  }
}

.ArchitekturaKrajobrazu__textBox .text {
  color: #111110;
  font-size: 13px;
  line-height: 22px;
}

.ArchitekturaKrajobrazu .btn {
  margin: 1rem 0 0 0;
  top: 0;
  transform: none;
}

.ArchitekturaKrajobrazu .btn a {
  color: #156606;
}

.ArchitekturaKrajobrazu .btn a:hover {
  color: #FFFFFF !important;
  text-decoration: none;
}

.ArchitekturaKrajobrazu .btn:hover {
  border-color: #FFFFFF !important;
  background: #156606 !important;
}

.ArchitekturaKrajobrazu h3 {
  margin: 1.3rem 0;
  font-size: 26px;
  color: #156606;
}

.ArchitekturaKrajobrazu__quote {
  color: #156606;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  margin: 5rem 0 1rem;
}

@media (max-width: 480px) {
  .ArchitekturaKrajobrazu__quote {
    padding: 0 2rem;
    font-size: 26px;
    line-height: 28px;
    padding: 0 2rem;
  }
}

.ArchitekturaKrajobrazu--Grey {
  background: #EFEFEF;
  padding: 4rem 0;
}

.ArchitekturaKrajobrazu--Grey__img {
  text-align: right;
}

.burger {
  width: 45px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
  transition: .3s;
  margin-top: -11px;
  background-color: transparent;
  border: 0;
}

.burger:hover, .burger:active, .burger:focus {
  outline: 0;
}

@media (min-width: 992px) {
  .burger {
    display: none;
  }
}

.burger span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #179494;
  margin-top: 6px;
  transition: .3s;
}

.burger span:nth-child(2) {
  transition: .1s !important;
}

.burger.active {
  /* margin-top:-20px; */
  margin-bottom: 2rem;
}

.burger.active span:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 13px;
}

.burger.active span:nth-child(2) {
  opacity: 0;
}

.burger.active span:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -10px;
}

#cookie-msg {
  padding: 12px !important;
  background-color: #000000;
  z-index: 100;
}

#cookie-msg .msg {
  font-size: 12px;
}

@media (min-width: 576px) {
  #cookie-msg .msg {
    font-size: 14px;
  }
}

#cookie-msg a {
  color: #111110 !important;
}

#cookie-msg .btn-aceptar {
  background-color: #000000;
  margin-left: 13px;
  font-size: 13px !important;
  color: #111110 !important;
  margin: 2px;
  display: inline-block;
}

.cta__baner {
  padding: 3rem 0;
  margin-bottom: 5rem;
  margin-top: 5rem;
  background: url("../../dist/images/tlo_pod_baner.png");
  background-repeat: no-repeat;
}

.cta__baner--white {
  background: none;
}

.cta__baner--white__left {
  color: #111110;
}

.cta__baner--white__left__subtitle {
  color: #111110;
}

@media (max-width: 480px) {
  .cta__baner {
    background-position-x: 50%;
    margin: 2rem 0 0 0;
  }
}

.cta__baner__left {
  color: #FFFFFF;
}

.cta__baner__left__title {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 480px) {
  .cta__baner__left__title {
    font-size: 26px;
  }
}

.cta__baner__left__subtitle {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
}

.cta__baner__left__subtitle span {
  font-size: 40px;
  font-weight: 700;
}

@media (max-width: 480px) {
  .cta__baner__left__subtitle {
    font-size: 16px;
  }
}

.cta__button--green {
  display: block;
  width: 299px;
  height: 55px;
  border: 1px solid #146505;
  border-radius: 30px;
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cta__button a {
  color: #156606;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.cta__button a:hover {
  color: #FFFFFF !important;
  text-decoration: none;
}

.footer {
  background-color: #156606;
  color: #FFFFFF;
  padding: 3rem 0;
}

.footer__data {
  color: #FFFFFF;
  font-size: 16px;
}

@media (max-width: 768px) {
  .footer__data {
    text-align: center;
  }
}

.footer__data__logo {
  margin: 2.5rem 0;
}

@media (max-width: 480px) {
  .footer__data__logo img {
    width: 150px;
  }
}

.footer__data__smIcons a {
  margin-right: .2rem;
}

.footer__data__address {
  margin: 2.5rem 0;
}

.footer__menu {
  text-align: center;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

@media (max-width: 480px) {
  .footer__menu {
    border: 0;
  }
}

.footer__menu ul#menu-menu-footer {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0px solid #fff;
}

@media (max-width: 480px) {
  .footer__menu ul#menu-menu-footer {
    margin: 2rem 0;
  }
}

.footer__menu ul#menu-menu-footer a {
  font-size: 16px;
  color: #FFFFFF;
}

.footer__menu ul#menu-menu-footer li {
  margin: 0;
  padding: 0;
  line-height: 20px;
  text-align: center;
}

@media (max-width: 480px) {
  .footer__menu ul#menu-menu-footer li {
    line-height: 12px;
  }
}

.footer__ico {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.footer__ico p.ico {
  margin: 1rem 0 1rem 2rem;
}

.footer__ico p.ico a.ico {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 480px) {
  .footer__ico p.ico a.ico {
    font-size: 14px;
  }
}

.footer__ico p.ico img.ico {
  margin-right: .8rem;
}

@media (max-width: 480px) {
  .footer__ico p.ico img.ico {
    width: 30px;
  }
}

.AfterFooter {
  background-color: #fff;
}

.AfterFooter__copyBox {
  padding: 10px 0;
  font-size: 14px;
}

@media (max-width: 768px) {
  .AfterFooter__copyBox {
    font-size: 12px;
  }
}

.AfterFooter__copyBox__Model {
  text-align: left;
}

@media (max-width: 600px) {
  .AfterFooter__copyBox__Model {
    text-align: center;
  }
}

.AfterFooter__copyBox__Anronet {
  text-align: right;
}

@media (max-width: 600px) {
  .AfterFooter__copyBox__Anronet {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .header__menu ul li {
    margin: .2rem 0;
    padding-left: .4rem;
  }
}

.header__icoPhone {
  margin: 0;
}

.header__icoPhone a.ico {
  color: #156606;
  font-size: 20px;
  font-weight: 500;
  font-size: 12px;
  font-weight: 500;
}

.header__icoPhone img.ico {
  margin-right: .8rem;
  width: 25px;
}

@media (max-width: 768px) {
  .header__icoPhone img.ico {
    width: 25px;
  }
}

.header__icoPhone.nofixed {
  display: block;
}

.header__icoPhone.fixed {
  display: none;
}

.header__logo {
  width: 160px;
  height: auto;
}

@media (max-width: 768px) {
  .header__logo {
    width: 160px;
    height: auto;
  }
}

@media (max-width: 600px) {
  .header__logo {
    width: 150px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .header__logo {
    width: 120px;
    height: auto;
  }
}

@media (max-width: 360px) {
  .header__logo {
    width: 100px;
    height: auto;
  }
}

.header #navbarNavDropdown {
  justify-content: center;
  flex-direction: row-reverse;
}

.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  width: 100%;
  max-width: unset;
}

.header--fixed #navbarNavDropdown {
  flex-direction: unset;
}

.header--fixed .header__logo {
  height: 35px;
  width: auto;
}

.header--fixed .header__icoPhone.nofixed {
  display: none;
}

@media (max-width: 768px) {
  .header--fixed .header__icoPhone.nofixed {
    display: none !important;
  }
}

.header--fixed .header__icoPhone.fixed {
  display: block;
}

.header--fixed .header__icoPhone {
  margin: 0;
}

.header--fixed .header__icoPhone a.ico {
  color: #156606;
  font-size: 12px;
  font-weight: 500;
}

.header--fixed .header__icoPhone img.ico {
  margin-right: .8rem;
  height: 25px;
}

.header--fixed ul#menu-main-manu a {
  color: #339a99;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
}

.header--fixed .header__calltoUs .svg img {
  width: 20px;
  height: 20px;
}

.header--fixed .header__calltoUs a {
  color: #339a99;
  font-size: 22px;
  margin-left: .4rem;
}

.header--fixed .navbar {
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  max-width: 1122px;
}

@media (max-width: 768px) {
  .header--fixed .navbar {
    margin: 0 auto;
    padding: 10px 10% 0;
  }
}

.header--fixed .dropdown-menu {
  margin-top: 0 !important;
}

.JakPobrac {
  margin-top: 4rem;
  padding-bottom: 0;
}

@media (max-width: 480px) {
  .JakPobrac {
    margin-top: 2rem;
  }
}

.JakPobrac__titleBox {
  display: flex;
  margin: 0 auto;
  align-items: center;
  margin-bottom: 3rem;
  flex-grow: 2;
  justify-content: center;
}

@media (max-width: 480px) {
  .JakPobrac__titleBox {
    flex-direction: column;
  }
}

.JakPobrac__titleBox .ico {
  margin-right: .5rem;
}

@media (max-width: 480px) {
  .JakPobrac__titleBox .ico img {
    width: 150px;
  }
}

.JakPobrac__titleBox .txt {
  margin-left: .5rem;
  text-align: center;
}

.JakPobrac__titleBox .txt .titleSection {
  text-align: center;
  margin-bottom: 2rem;
}

.JakPobrac__icons {
  display: flex;
  align-items: baseline;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

.JakPobrac__icons .icoBox {
  text-align: center;
  max-width: 130px;
}

.JakPobrac__icons .icoBox img {
  margin-bottom: .5rem;
}

.JakPobrac__icons .icoBox p {
  font-size: 12px;
  color: #231F20;
}

.JakPobrac__button {
  margin-top: 4rem;
  text-align: center;
}

.mainSlider {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 30px;
}

@media (max-width: 480px) {
  .mainSlider {
    height: 50vh;
    min-height: 300px;
  }
}

@media (max-width: 480px) {
  .mainSlider .item {
    height: 50vh;
    min-height: 300px;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
  }
}

.mainSlider .item .textBox {
  position: absolute;
  width: 100%;
  top: 30%;
}

@media (max-width: 480px) {
  .mainSlider .item .textBox {
    top: 15%;
  }
}

.mainSlider .item .textBox__container {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2.5rem;
  margin: 0 auto;
  width: 50%;
}

@media (max-width: 480px) {
  .mainSlider .item .textBox__container {
    padding: .5rem;
    width: 70%;
  }
}

.mainSlider .item .textBox__container .mainText {
  color: #111110;
  font-size: 46px;
  font-weight: 700;
}

@media (max-width: 480px) {
  .mainSlider .item .textBox__container .mainText {
    font-size: 26px;
    font-weight: 700;
  }
}

.mainSlider .item .textBox__container .secondText {
  color: #727272;
  font-size: 30px;
  font-weight: 400;
}

@media (max-width: 480px) {
  .mainSlider .item .textBox__container .secondText {
    font-size: 20px;
  }
}

.mainSlider .item .textBox__container .btnBox {
  margin-top: 3rem;
}

.mainSlider .item .textBox__container .button--green {
  display: block;
  width: 240px;
  height: 50px;
  border: 1px solid #146505;
  border-radius: 30px;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 480px) {
  .mainSlider .item .textBox__container .button--green {
    width: 200px;
    height: 40px;
  }
}

.mainSlider .item .textBox__container .button--green a {
  padding: 5px 0 0 0;
  display: block;
  font-size: 20px;
  text-transform: uppercase;
}

@media (max-width: 480px) {
  .mainSlider .item .textBox__container .button--green a {
    padding: 2px 0 0 0;
    font-size: 16px;
  }
}

.mainSlider .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: -2rem;
  z-index: 5;
  position: relative;
}

@media (max-width: 480px) {
  .woocommerce div.product form.cart div.quantity {
    flex-direction: row;
    display: flex;
    text-align: center;
    justify-content: center;
    float: unset !important;
    margin: unset !important;
  }
}

@media (max-width: 991px) {
  #mainContainerShop {
    padding: 0 15px;
  }
}

@media (max-width: 480px) {
  #mainContainerShop .titleSection {
    font-size: 30px;
    line-height: 28px;
  }
}

#mainContainerShop .woocommerce-breadcrumb {
  display: none !important;
}

#mainContainerShop .btn.cta__button--green {
  margin: 0rem auto;
}

@media (max-width: 480px) {
  #mainContainerShop .btn.cta__button--green {
    margin: 2rem auto 0;
  }
}

#mainContainerShop .cart {
  margin-top: 2rem;
}

#mainContainerShop .quantity input {
  border: 1px solid #156606;
  padding: .4rem;
  width: 4rem;
  border-radius: 30px;
  width: 100px;
  height: 55px;
  margin-top: -3px;
}

@media (max-width: 480px) {
  #mainContainerShop .quantity input {
    width: 80px;
    height: 35px;
  }
}

#mainContainerShop .posted_in {
  display: none !important;
}

#mainContainerShop .tabs.wc-tabs {
  display: none !important;
}

#mainContainerShop #tab-description h2 {
  display: none !important;
}

#mainContainerShop div.product p.price, #mainContainerShop div.product span.price {
  color: #156606;
  font-size: 2rem;
  font-weight: 900;
  margin: 1rem 0;
}

#sidebar {
  display: none !important;
}

.button.wc-forward {
  display: none !important;
}

.woocommerce-message {
  border-top-color: #156606 !important;
}

.woocommerce-message::before {
  content: "\e015";
  color: #156606 !important;
}

.woocommerce-Price-currencySymbol {
  margin-left: .2rem;
}

.cart_totals h2 {
  padding-bottom: 2rem;
  color: #000000;
  line-height: 2.3rem;
  font-weight: 700;
}

@media (max-width: 480px) {
  .cart_totals h2 {
    font-size: 26px;
    text-align: center;
  }
}

.wc-proceed-to-checkout button,
button#place_order {
  margin-top: 2rem;
}

.pageStandard #respond input#submit, .pageStandard a.button, .pageStandard button.button, .pageStandard input.button {
  color: #fff !important;
  background-color: #156606 !important;
  border: 1px solid #156606 !important;
}

.pageStandard #respond input#submit:hover, .pageStandard a.button:hover, .pageStandard button.button:hover, .pageStandard input.button:hover {
  background-color: white !important;
  color: #156606 !important;
  border: 1px solid #156606 !important;
}

.pageStandard .breadcrumbs,
.pageStandard .woocommerce-form-coupon-toggle {
  display: none !important;
}

.woocommerce-checkout form .form-row input.input-text,
.woocommerce-checkout form .form-row textarea {
  box-sizing: border-box;
  width: 100%;
  margin: 3px 0;
  padding: 5px;
  outline: 0;
  line-height: normal;
  border: 1px solid #156606;
}

.woocommerce-checkout form .form-row {
  padding: 3px;
  margin: 0 0 6px;
  display: contents;
}

.woocommerce-checkout form .form-row label {
  line-height: unset;
  margin-top: 10px;
}

.woocommerce-checkout form h3 {
  margin-top: 2rem;
}

@media (max-width: 480px) {
  .woocommerce-checkout form h3 {
    margin-top: 1rem;
    font-size: 1.35rem;
  }
}

.woocommerce-checkout h3 {
  padding-bottom: 2rem;
  color: #000000;
  line-height: 1.5rem;
  font-weight: 500;
}

@media (max-width: 480px) {
  .woocommerce-checkout h3 {
    padding-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .woocommerce-checkout #ship-to-different-address {
    margin-top: 2rem;
  }
}

.woocommerce-checkout #billing_state_field {
  display: none !important;
}

.woocommerce-order h3 {
  font-size: 1.35rem;
}

.woocommerce-order .woocommerce-order-details__title {
  font-size: 1.5rem;
  font-weight: 500;
}

@media (max-width: 480px) {
  .woocommerce-order .woocommerce-order-details__title {
    font-size: 1.1rem;
  }
}

.woocommerce-order .woocommerce-column__title {
  font-size: 1.5rem;
  font-weight: 500;
}

@media (max-width: 480px) {
  .woocommerce-order .woocommerce-column__title {
    font-size: 1.1rem;
  }
}

.woocommerce-order .woocommerce-thankyou-order-received,
.woocommerce-order .woocommerce-thankyou-order-details,
.woocommerce-order .woocommerce-bacs-bank-details {
  border: 0px solid #156606;
  padding: 2rem;
}

.woocommerce-order ul.order_details li {
  border-right: 1px dashed #156606 !important;
}

.woocommerce-order section.woocommerce-order-details {
  margin: 0;
  padding: 1rem 2rem;
}

.woocommerce-order section.woocommerce-customer-details {
  margin-bottom: 2em;
  padding: 1rem 2rem;
}

.woocommerce .col2-set .col-1,
.woocommerce-page .col2-set .col-1,
.woocommerce .col2-set .col-2,
.woocommerce-page .col2-set .col-2 {
  max-width: 48%;
}

@media (max-width: 768px) {
  .woocommerce .col2-set .col-1,
  .woocommerce-page .col2-set .col-1,
  .woocommerce .col2-set .col-2,
  .woocommerce-page .col2-set .col-2 {
    max-width: 100%;
    margin: 1.5rem 0;
  }
}

.woocommerce table.shop_table th,
.woocommerce table.shop_table td {
  font-size: 14px !important;
}

@media (max-width: 480px) {
  .woocommerce table.shop_table th,
  .woocommerce table.shop_table td {
    font-size: 12px !important;
  }
}

#map {
  width: 100%;
}

.NajlepszeDla {
  margin-top: 4rem;
  background-color: #EFEFEF;
}

@media (max-width: 480px) {
  .NajlepszeDla {
    margin: 0;
  }
}

.NajlepszeDla__icons {
  display: flex;
  align-items: end;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

.NajlepszeDla__icons .icoBox {
  text-align: center;
}

@media (max-width: 480px) {
  .NajlepszeDla__icons .icoBox {
    margin: 0 1rem 2rem;
  }
}

.NajlepszeDla__icons .icoBox img {
  margin-bottom: .5rem;
}

.NajlepszeDla__icons .icoBox p {
  font-size: 12px;
  color: #231F20;
}

@media (max-width: 480px) {
  .NajlepszeDla__icons .icoBox p {
    font-size: 10px;
  }
}

.NajlepszeDla .titleSection {
  text-align: center;
  margin-bottom: 2rem;
}

/* 
@include rwd(large-phone) {
    #navbarNavDropdown {
        display: block !important;
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 1rem;
    }    
}
 */
ul#menu-main-manu li {
  padding: 0 10px;
}

ul#menu-main-manu a {
  color: #339A99;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 991px) {
  ul#menu-main-manu a {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  ul#menu-main-manu a {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  ul#menu-main-manu a:hover {
    -webkit-animation-name: hvr-pulse;
    animation-name: hvr-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}

.navbar-collapse {
  padding: 10px 0;
}

@media (min-width: 992px) {
  .navbar-collapse {
    padding: 0;
  }
}

.navbar-collapse .nav-item:nth-child(n+4) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(1) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(2) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(3) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(4) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(5) {
  margin-right: 20px;
}

@media (min-width: 992px) {
  .navbar-collapse .nav-item {
    padding: 27px 0;
  }
}

.navbar-collapse .nav-item.dropdown > a {
  padding-right: 27px !important;
  background: url("../images/svg/downArrow.svg") no-repeat top 16px right 10px;
  background-size: 12px;
}

.navbar-collapse .nav-item .nav-link {
  color: #111110;
  margin: 2px 5px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .3px;
  display: flex;
  align-items: center;
  padding: 10px 5px 5px 0;
}

@media (min-width: 992px) {
  .navbar-collapse .nav-item .nav-link {
    font-size: 12px;
    margin: 0 3px;
    padding: 9px 3px 5px 0 !important;
  }
}

@media (min-width: 1100px) {
  .navbar-collapse .nav-item .nav-link {
    padding: 9px 5px 5px 0 !important;
  }
}

@media (min-width: 1139px) {
  .navbar-collapse .nav-item .nav-link {
    font-size: 12px;
    margin: 0 3px;
  }
}

@media (min-width: 1410px) {
  .navbar-collapse .nav-item .nav-link {
    margin: 0 5px;
    padding: 9px nn5px 5px 0 !important;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .navbar-collapse .nav-item .nav-link {
    color: #111110;
    margin: 0px 0px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .3px;
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
}

.navbar-collapse .nav-item .nav-link:hover {
  text-decoration: underline;
}

.navbar-collapse .nav-item.active > .hvr-border-fade {
  box-shadow: inset 0 0 0 1px #fff, 0 0 1px rgba(0, 0, 0, 0);
}

.navbar-collapse .dropdown:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
  -webkit-animation-duration: unset;
  animation-duration: unset;
  -webkit-animation-timing-function: unset;
  animation-timing-function: unset;
  -webkit-animation-iteration-count: unset;
  animation-iteration-count: unset;
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown:hover .dropdown-menu {
    display: block !important;
  }
}

.navbar-collapse .dropdown .dropdown-menu {
  background-color: transparent;
  border: 0 !important;
  border-radius: 5px;
  min-width: 250px;
}

@media (max-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu {
    padding-top: 0;
    padding-left: 7px;
  }
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu {
    margin-top: -5px;
    display: none;
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.navbar-collapse .dropdown .dropdown-menu .nav-link {
  border-bottom: 1px solid #156606 !important;
  transition: .2s;
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu .nav-link {
    padding: 11px 7px 9px 7px !important;
  }
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade {
  border: 0 !important;
  border-bottom: 1px solid #156606 !important;
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade:hover {
  border: 0 !important;
  border-bottom: 1px solid #666 !important;
  box-shadow: none !important;
}

.OchronaSrodowiska {
  background: #EFEFEF;
  padding-bottom: 0;
  margin-top: 4rem;
}

@media (max-width: 480px) {
  .OchronaSrodowiska {
    margin: 0;
  }
}

.OchronaSrodowiska .parallax {
  /* Set a specific height */
  min-height: 60vh;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.OchronaSrodowiska .parallax .titleSection {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #FFFFFF;
}

.OchronaSrodowiska__txt {
  padding-bottom: 4rem;
  text-align: center;
}

.OchronaSrodowiska__txt p {
  line-height: 28px;
}

section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  overflow: hidden;
}

@media (max-width: 480px) {
  section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.stworzTrawnik {
  padding-bottom: .9rem;
}

.stworzTrawnik__container {
  max-width: 1280px;
  margin: 0 auto;
  background-color: #156606;
  box-shadow: 0px 0px 20px #000000B3;
}

.stworzTrawnik__textBox {
  padding: 2rem 4rem;
}

.stworzTrawnik__textBox .titleSection {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 1rem;
}

@media (max-width: 480px) {
  .stworzTrawnik__textBox .titleSection {
    font-size: 30px;
  }
}

.stworzTrawnik__textBox .text {
  color: #fff;
  font-size: 13px;
  line-height: 22px;
}

.stworzTrawnik .btn {
  margin: 1rem 0 0 0;
  top: 0;
  transform: none;
}

.stworzTrawnik .btn a {
  color: #156606;
}

.stworzTrawnik .btn a:hover {
  color: #FFFFFF !important;
  text-decoration: none;
}

.stworzTrawnik .btn:hover {
  border-color: #FFFFFF !important;
  background: #156606 !important;
}

.MainPowtarzalne {
  padding-bottom: 0;
}

.MainPowtarzalne__textBox {
  padding: 2rem 4rem;
}

.MainPowtarzalne__textBox .titleSection {
  margin-bottom: 1rem;
}

.MainPowtarzalne__textBox .text {
  line-height: 27px;
}

.MainPowtarzalne .btn {
  margin: 1rem 0 0 0;
  top: 0;
  transform: none;
}

.MainPowtarzalne .btn a {
  color: #156606;
}

.MainPowtarzalne .btn a:hover {
  color: #FFFFFF !important;
  text-decoration: none;
}

@media (max-width: 480px) {
  .MainPowtarzalne {
    padding: 0 15px;
  }
}

.BGpause--odd {
  background: url("../../dist/images/svg/pasek_poziomy_1.svg");
  background-repeat: no-repeat;
  text-align: center;
  margin: 0 auto;
  width: 663px;
  height: 134px;
}

@media (max-width: 480px) {
  .BGpause--odd {
    display: none !important;
  }
}

.BGpause--even {
  background: url("../../dist/images/svg/pasek_poziomy_2.svg");
  background-repeat: no-repeat;
  text-align: center;
  margin: 0 auto;
  width: 663px;
  height: 134px;
}

@media (max-width: 480px) {
  .BGpause--even {
    display: none !important;
  }
}

.ZrobToSam {
  padding-bottom: .9rem;
}

.ZrobToSam__container {
  width: 1280px;
  margin: 0 auto;
  background-color: #156606;
  box-shadow: 0px 0px 20px #000000B3;
}

@media (max-width: 480px) {
  .ZrobToSam__img {
    padding: 0 2rem;
    margin-bottom: 2rem;
  }
}

.ZrobToSam__textBox {
  padding: 2rem 4rem;
}

@media (max-width: 480px) {
  .ZrobToSam__textBox {
    padding: 0 2rem;
  }
}

.ZrobToSam__textBox .titleSection {
  font-size: 40px;
  color: #111110;
  font-weight: 900;
  margin-bottom: 1rem;
}

@media (max-width: 480px) {
  .ZrobToSam__textBox .titleSection {
    font-size: 30px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    margin-bottom: 2rem;
  }
}

.ZrobToSam__textBox .text {
  color: #111110;
  font-size: 13px;
  line-height: 22px;
}

.ZrobToSam .btn {
  margin: 1rem 0 0 0;
  top: 0;
  transform: none;
}

.ZrobToSam .btn a {
  color: #156606;
}

.ZrobToSam .btn a:hover {
  color: #FFFFFF !important;
  text-decoration: none;
}

.ZrobToSam .btn:hover {
  border-color: #FFFFFF !important;
  background: #156606 !important;
}

.ZrobToSam h3 {
  margin: 1.3rem 0;
  font-size: 26px;
  color: #156606;
}

.ZrobToSam__quote {
  color: #156606;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  margin: 5rem 0 1rem;
}

@media (max-width: 480px) {
  .ZrobToSam__quote {
    padding: 0 2rem;
    font-size: 26px;
    line-height: 28px;
    padding: 0 2rem;
  }
}

.JakToDziala {
  padding-bottom: .9rem;
}

.JakToDziala__container {
  width: 1280px;
  margin: 0 auto;
  background-color: #156606;
  box-shadow: 0px 0px 20px #000000B3;
}

@media (max-width: 480px) {
  .JakToDziala__img {
    padding: 0 2rem;
  }
}

.JakToDziala__textBox {
  padding: 2rem 4rem;
}

@media (max-width: 480px) {
  .JakToDziala__textBox {
    padding: 0 2rem;
  }
}

.JakToDziala__textBox .titleSection {
  font-size: 40px;
  color: #111110;
  font-weight: 900;
  margin-bottom: 1rem;
}

@media (max-width: 480px) {
  .JakToDziala__textBox .titleSection {
    font-size: 30px;
    line-height: 28px;
  }
}

.JakToDziala__textBox .subtitleSection {
  color: #F0A92D;
  font-size: 26px;
  font-weight: 700;
  line-height: 27px;
  padding-bottom: .5rem;
  margin-bottom: 0;
}

@media (max-width: 480px) {
  .JakToDziala__textBox .subtitleSection {
    font-size: 22px;
    line-height: 18px;
  }
}

.JakToDziala__textBox .text {
  color: #111110;
  font-size: 13px;
  line-height: 22px;
}

.JakToDziala .btn {
  margin: 1rem 0 0 0;
  top: 0;
  transform: none;
}

.JakToDziala .btn a {
  color: #156606;
}

.JakToDziala .btn a:hover {
  color: #FFFFFF !important;
  text-decoration: none;
}

.JakToDziala .btn:hover {
  border-color: #FFFFFF !important;
  background: #156606 !important;
}

.JakToDziala h3 {
  margin: 1.3rem 0;
  font-size: 26px;
  color: #156606;
}

.JakToDziala h4 {
  margin: 1rem 0 .8rem;
  font-size: 18px;
  color: #111110;
}

.JakToDziala__quote {
  color: #156606;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  margin: 5rem 0 1rem;
}

.JakToDziala--BGpause {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.JakZrozumiec {
  background-color: #EFEFEF;
}

.JakZrozumiec .titleSection {
  font-size: 48px;
  color: #111110;
  font-weight: 900;
  margin-bottom: 1rem;
}

@media (max-width: 480px) {
  .JakZrozumiec .titleSection {
    font-size: 30px;
    line-height: 28px;
  }
}

.JakZrozumiec__textBox h4 {
  margin: 2rem 0 1rem;
  font-size: 18px;
  font-weight: 700;
  color: #111110;
}

.PoprawJakosc {
  background-color: #fff;
}

.PoprawJakosc .titleSection {
  font-size: 48px;
  color: #111110;
  font-weight: 900;
  margin-bottom: 3rem;
}

@media (max-width: 480px) {
  .PoprawJakosc .titleSection {
    font-size: 30px;
    line-height: 28px;
  }
}

.PoprawJakosc__textBox h4 {
  margin: 0 0 1rem 0;
  font-size: 24px;
  font-weight: 900;
  color: #111110;
  text-align: left;
  text-transform: uppercase;
}

.PoprawJakosc__textBox p.txt {
  margin-top: 2rem;
  font-size: 14px;
}

.PoprawJakosc__textBox p.txt--left {
  padding-right: 11rem;
  text-align: left;
}

@media (max-width: 480px) {
  .PoprawJakosc__textBox p.txt--left {
    padding: 0;
    margin-bottom: 1rem;
    text-align: justify;
  }
}

.PoprawJakosc__textBox p.txt--right {
  padding-left: 11rem;
  text-align: right;
}

@media (max-width: 480px) {
  .PoprawJakosc__textBox p.txt--right {
    padding: 0;
    margin-bottom: 1rem;
    text-align: justify;
  }
}

.PoprawJakosc__wpinka {
  position: relative;
  top: -26rem;
  text-align: center;
  margin-bottom: -26rem;
}

@media (max-width: 768px) {
  .PoprawJakosc__wpinka {
    display: none;
  }
}

.pageStandard .titlePage {
  padding-bottom: 2rem;
  color: #000000;
  line-height: 2.3rem;
  font-weight: 700;
}

@media (max-width: 1024px) {
  .pageStandard .titlePage {
    font-size: 30px;
    line-height: 28px;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .pageStandard.mainContainer {
    padding: 0 15px;
  }
}
