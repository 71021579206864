.footer{
    background-color: $cGreen;
    color: $cWhite;
    padding: 3rem 0;

    &__data{
        color: $cWhite;
        font-size: 16px;
        @include rwd(tablet) {
            text-align: center;
        }
        &__logo{
            margin: 2.5rem 0;
            img{
                @include rwd(large-phone) {
                    width: 150px;
                }                
            }
        }
        &__smIcons{
            a{
                margin-right: .2rem;;
            }
        }
        &__address{
            margin: 2.5rem 0;
        }
    }

    &__menu {
        text-align: center;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        @include rwd(large-phone) {
            border: 0;
        }
        ul#menu-menu-footer {
            list-style: none;
            margin: 0;
            padding: 0;
            border: 0px solid #fff;
            @include rwd(large-phone) {
                margin: 2rem 0;
            }
            a {
                font-size: 16px;
                color: $cWhite;
            }
            li {
                margin: 0;
                padding: 0;
                line-height: 20px;
                text-align: center;
                @include rwd(large-phone) {
                    line-height: 12px;
                }
            }
        }
    }

    &__ico {
        display: flex;
        justify-content: center;
        flex-direction: column;    

        p.ico{
            margin: 1rem 0 1rem 2rem;
            a.ico{
                color: #fff;
                font-size: 20px;
                font-weight: 500;
                @include rwd(large-phone) {
                    font-size: 14px;
                }
            }
            img.ico{
                margin-right: .8rem;
                @include rwd(large-phone) {
                    width: 30px;
                }
            }
        }
    }

}
    
.AfterFooter{
    background-color: #fff;
    &__copyBox {
        padding: 10px 0;
        font-size: 14px;
        @include rwd(tablet) {
            font-size: 12px;
        }
        &__Model{
            text-align: left;
            @include rwd(small-tablet) {
                text-align: center;
            }
        }
        &__Anronet{
            text-align: right;
            @include rwd(small-tablet) {
                text-align: center;
            }
        }
    }
}